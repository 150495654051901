<template>
  <div>
    <label v-if="label" class="label">{{label}}{{requiredAstrisk}}</label>
    <div :class="{'percent': true, 'percent-compact': compact}">
      <input v-model="newValue"
             :disabled="disabled"
             @input="valueUpdated" />
      <v-btn dark
             small
             color="secondary"
             :class="{'percent-button': true, 'percent-button-compact': compact}"
             style="top: 0; border-radius: 0 2px 0 0;"
             :disabled="disabled"
             @click="increment(1)">
        <i class="fas fa-chevron-up"></i>
      </v-btn>
      <v-btn dark
             small
             color="secondary"
             :class="{'percent-button': true, 'percent-button-compact': compact}"
             style="bottom: 0; border-radius: 0 0 2px 0;"
             :disabled="disabled"
             @click="increment(-1)">
        <i class="fas fa-chevron-down"></i>
      </v-btn>
    </div>
    <span>
      <ErrorMessages :errors="errors"></ErrorMessages>
    </span>
  </div>
</template>

<script>

  export default {
    name: "PercentIncrementer",
    components: {
    },
    props: {
      value: {
        type: [Number, String],
        default: 0
      },
      label: String,
      required: Boolean,
      clearable: Boolean,
      disabled: Boolean,
      errors: Array,
      compact: Boolean,
      exclusionValues: {
        type: Array,
        default: null
      },
      decimal: {
        type: Boolean,
        default: false
      }, 
    },
    emits: [
      'change'
    ],
    data() {
      return {
        newValue: this.formatPercent(this.value + '')
      }
    },
    computed: {
      requiredAstrisk() {
        return this.required ? '*' : ''
      }
    },
    methods: {
      emitValue(v) {
        if (isNaN(v)) {
          v = 0;
          this.newValue = '0%';
        }
        this.$emit('change', v)
      },
      increment(val) {
        if (this.exclusionValues && this.exclusionValues.length > 0) {
          let computedVal = parseInt(this.newValue) + val

          const minValue = Math.min(...this.exclusionValues)
          const maxValue = Math.max(...this.exclusionValues)

          while (this.exclusionValues.includes(computedVal) && computedVal >= minValue && computedVal <= maxValue) {
            computedVal = computedVal + val
          }

          this.newValue = this.formatPercent(computedVal)
        } else {

          if (this.decimal) {
            this.newValue = this.formatPercent(parseFloat(this.newValue) + val)
          } else {
            this.newValue = this.formatPercent(parseInt(this.newValue) + val)
          }
        }

        this.handleValueEmit()
      },
      formatPercent(val) {
        let newValue = 0;
        if (this.decimal) {
          newValue = parseFloat(val).toFixed(2)
        } else {
          newValue = parseInt(val)
        }
        return Math.max(newValue, 0) + '%';
      },
      valueUpdated(e) {
        let input = e.currentTarget;
        var inputVal = input.value;
        inputVal = inputVal.replace('%', '');
        this.newValue = this.formatPercent(inputVal);
        this.handleValueEmit()
      },
      handleValueEmit() { 
        const v = this.newValue.replace('%', '');
        if (this.decimal) {
          this.emitValue(parseFloat(v));
        } else {
          this.emitValue(parseInt(v));
        }
      }
    },
    watch: {
      value(val) {
        this.newValue = this.formatPercent(val + '') 
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '@/style.scss';
  .label {
    font-size: 12px;
  }

  .percent {
    border: 2px solid #002B49;
    border-radius: 4px;
    padding: 10px 12px;
    position: relative;
    margin-top: -1px;
  }

  .percent-compact {
    padding: 0px 12px !important;
  }

  .percent input {
    border: none !important;
    outline: none !important;
    line-height: 32px;
    font-size: 16px;
    width: 100%;
  }

  .percent-button {
    position: absolute;
    right: 0;
    height: 28px!important;
    width: 38px!important;
    min-width: 38px!important;
  }

  .percent-button-compact {
    height: 16px !important;
  }

</style>
